import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const BypassPage = () => {
  return (
    <Layout pageTitle="Bypass">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage 
                className="hero-image"
                src="../../media/bypass_hero.png"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Bypass</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <StaticImage 
                src="../../media/bypass_truck.png"
                alt="black truck facing right icon" />
              <p>
                At Wessuc, we haul bulk liquid and solids from virtually any site or location. Our large fleet of trucks and equipment are designed to maximize volumes and reduce back and forth traffic.
              </p>
              <p>
                GPS is used to track the speed and travel route of each unit, ensuring compliance and improving operational efficiency. 
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/bypass_certificate.png"
                alt="black certificate icon" />
              <p>
                Facility bypasses, such as pumping stations, are completed with our pumps and loading equipment. Wessuc's equipment can be specified to meet the facility flowrates and uses over-head loading to eliminate spills.
              </p>
              <p>
                Wessuc Manages over 700,000m3 of material/year, with 21 trailers, 38-45m3 tankers that use overhead loading.
              </p>
            </Col>
          </Row>
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <StaticImage 
                src="../../media/bypass_pump.png"
                alt="black pump icon" />
              <p>
                With a Wessuc Bypass we are able to ensure you can keep pump stations operating and wasterwater moving properly. 
              </p>
              <p>
                For residential developments, Wessuc can divert waste as a temporary solution to make sure site managers stay on track with the development projects. 
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/bypass_plan.png"
                alt="black icon of a plan with a pencil checking off a step" />
              <p>
                Wessuc has staffed engineers that map out the bypass location, from which they will draft up the plans for your specific bypass project.
              </p>
              <p>
                Wessuc has experience in a variety bypass work of all capacities.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl">
          <Row>
            <Col>
              <Link to="/contact" className="default-text">
                <Button variant="primary" className="shadow">CONTACT US TODAY FOR YOUR BYPASS SET UP</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default BypassPage